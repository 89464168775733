/* eslint no-eval: 0 */

import './App.css';
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridActionsCellItem, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Typography,DialogTitle } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import clsx from 'clsx';
import moment from 'moment'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';//import {LineChart, Line} from 'recharts';

import {ComposedChart,Scatter, Area, XAxis,YAxis,ResponsiveContainer,Tooltip } from 'recharts';

function Newstable() {

  const [tableData,setTableData]=useState([]);
  const [tradeData,setTradeData]=useState([]);
  const defaultLobData= [{
    a1_p:0,
    a2_p:0,
    a3_p:0,
    a4_p:0,
    a5_p:0,
    a1_v:0,
    a2_v:0,
    a3_v:0,
    a4_v:0,
    a5_v:0,
    b1_p:0,
    b2_p:0,
    b3_p:0,
    b4_p:0,
    b5_p:0,
    b1_v:0,
    b2_v:0,
    b3_v:0,
    b4_v:0,
    b5_v:0,
  }];
  
  const [Tradelog,setTradelog]=useState([]);
  const [Tradelogloading, setTradelogloading] = useState(true);
  
  const [lobData,setLobData]=useState(defaultLobData);
  const defaultModalData= [{name:"",isin:"",symbol:""}];
  const [modalData, setModalData] = useState(defaultModalData);
  const [loading, setLoading] = useState(true);
  const [maxPrice, setMaxPrice] = useState(0);
  const [closedTradesLoading, setClosedTradesLoading] = useState(true);
  
  var heads = {}
  try {
    const cred = JSON.parse(localStorage.getItem("credentials"));
    const apikey = cred['access_token'];
    heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
    heads = {}
  }
  
  function loadTable() {
    fetch("http://46.246.30.100/api/get/news/100", { headers:heads})
        .then((response) => response.json())
        .then((data) => {setTableData(data); setLoading(false); })
  }
  
  function loadFullTable() {
    fetch("http://46.246.30.100/api/get/news", { headers:heads})
        .then((response) => response.json())
        .then((data) => {setTableData(data); setLoading(false); })
  }
  
  function loadModalGraph(nid,isin,time) {
    fetch("http://46.246.30.100/api/newsmodal/graph2", { 
      headers:heads,
      method: "post",
      body: JSON.stringify({"nid":nid,"isin":isin,"time":time})})
        .then((response) => response.json())
        .then((data) => {setTradeData(data); setClosedTradesLoading(false); })
  }
  
  function loadLobData(isin,time) {
    fetch("http://46.246.30.100/api/lob", { 
      headers:heads,
      method: "post",
      body: JSON.stringify({"isin":isin,"time":time})})
        .then((response) => response.json())
        .then((data) => { setMaxPrice(Math.max(
          data[0].a1_v,
          data[0].a2_v,
          data[0].a3_v,
          data[0].a4_v,
          data[0].a5_v,
          data[0].b1_v,
          data[0].b2_v,
          data[0].b3_v,
          data[0].b4_v,
          data[0].a5_v,
        )); setLobData(data); })
  }
  
  function loadTradelog(nid) {
    fetch("http://46.246.30.100/api/tradelog", {  
      headers:heads,
      method: "post",
      body: JSON.stringify({"nid":nid})})
    .then((response) => response.json())
    .then((data) => {setTradelog(data); setTradelogloading(false); })
  }
  
  function loadModalData(nid,isin,time) {
    fetch("http://46.246.30.100/api/newsmodal/data", { 
      headers:heads,
      method: "post",
      body: JSON.stringify({"nid":nid,"isin":isin,"time":time})})
        .then((response) => response.json())
        .then((data) => {setModalData(data); })
  }
  
  useEffect(()=>{
    loadTable();
    loadFullTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  
  const [openModal, setOpenModal] = React.useState(false);
  const handleClickOpenModal  = (params) => { setOpenModal(true); loadModalGraph(params.row.nid,params.row.isin,params.row.time); loadModalData(params.row.nid,params.row.isin,params.row.time); loadLobData(params.row.isin,params.row.time); loadTradelog(params.row.nid) };
  const handleCloseModal = () => {setOpenModal(false); setTradeData([]); setModalData(defaultModalData); setLobData(defaultLobData);};
  
  
  function getSignalCalc(params){
    if (params.aggregation) { return params.formattedValue;}
    else {
      if (params.value === 1) {
        return <CheckIcon color="text.primary" />;
      }
      return "";
    }
  }
  
  function getDate(params) {
    var date = new Date(params.value);
    if(!isNaN(date.getTime())) {
      return date;
    }
    else { return ""; }
  }
  
  const MemoizedgetSignal = React.memo(getSignalCalc);
  
  function getSignal(params: GridRenderCellParams<number>) {
    return <MemoizedgetSignal {...params} />;
  }
  
  function formatPerc(params){
    const formattedValue = ((Math.round(params.value*10000)/10000)*100);
    return formattedValue.toLocaleString('se')+" %"; 
  }
  
  function formatOurPerc(params){
    if (params.value === null) {return ""}
    const formattedValue = ((Math.round(params.value*10000)/10000)*100);

    return formattedValue.toLocaleString('se')+" %"; 
    
  }
  
  function formatSizePerc(params){
    if (params.value === null || params.value === 0) {return ""}
    const formattedValue = ((Math.round(params.value*10000)/10000)*100);
  
    return formattedValue.toLocaleString('se')+" %"; 
    
  }
  
  function formatRound(params){
    if (params.value === null) {return ""}
    const formattedValue = Math.round(params.value);

    return formattedValue.toLocaleString('se')+" SEK"; 
    
  }
  
  function removeNull(params){
    if (params.value !== null && params.value !== undefined && params.value !== "") {
      return params.value;
    }
    else {return 0;}
  }
  
  function addMin(params) {
    if (params.value === null) {return ""}
    return (params.value+" min");
    
  }
  
  function addUs(params) {
    if (params.value === null) {return ""}
    return (params.value+" μs");
    
  }
  
  const columns: GridColDef[] = React.useMemo(
  () => [

    { field: 'actions', width:50, headerName: '', type:'actions', getActions: (params) => [
      <GridActionsCellItem icon={<InfoOutlinedIcon />} onClick={() => handleClickOpenModal(params)} label="Info"  />,
    ], filterable: false,
     groupable:false,
     sortable:false,
     aggregable:false,
     },
     { 
         field: 'rowmumber' , 
         headerName: 'Radnr', 
         filterable: false,
         groupable:false,
         sortable:false,
         aggregable:false,
         renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
         width: 20
     },
    { field: 'id', headerName: 'id', type:'number', groupable: false},
    { field: 'language', headerName: 'Språk', type:'string', groupable: false},
    { field: 'nid', headerName: 'nid', type:'string', groupable: false},
    { field: 'isin', headerName: 'isin', type:'string', groupable: false},
    { field: 'time', headerName: 'Tidsstämpel', type:'dateTime', valueGetter: getDate, width: 150, groupable: false},
    { field: 'name', headerName: 'Bolagsnamn', type:'string', width: 150  },
    { field: 'headline', headerName: 'Rubrik', type:'string', width: 500, groupable: false},
    { field: 'prediction', headerName: 'Prediction', type:'number', width:68},
    { field: 'prediction_v2', headerName: 'Prediction V2', type:'number', width:68},
    { field: 'lobb', headerName: 'LOBB', type:'number', width:68},
    { field: 'takeposition', headerName: 'Köpsignal', type:'number', renderCell: getSignal, width:68 },
    { field: 'stopwords', headerName: 'Stoppord', type:'number', renderCell: getSignal, width:68 },
    { field: 'goodwords', headerName: 'Köpord', type:'number', renderCell: getSignal, width:68 },
    { field: 's1_returns', headerName: '1s', type:'number', valueFormatter: formatPerc, valueGetter: removeNull, width:68},
    { field: 's30_returns', headerName: '30s', type:'number', valueFormatter: formatPerc, valueGetter: removeNull, width:68},
    { field: 'm2_returns', headerName: '2m', type:'number', valueFormatter: formatPerc, valueGetter: removeNull, width:68},
    { field: 'm30_returns', headerName: '30m', type:'number', valueFormatter: formatPerc, valueGetter: removeNull, width:68},
    { field: 'relative_position', headerName: 'Storlek', type:'number', valueFormatter: formatSizePerc, width:68},
    { field: 'internal_latency', headerName: 'Intern Latens (μs)', type:'number',  valueFormatter: addUs, width:68},
    { field: 'latency', headerName: 'Total Latens (μs)', type:'number',  valueFormatter: addUs, width:68},
    { field: 'timeinmarket', headerName: 'Tid (min)', type:'number',  valueFormatter: addMin, width:68},
    { field: 'pnl', headerName: 'PnL (SEK)', type:'number', valueFormatter: formatRound, width:68},
    { field: 'pnl_perc', headerName: 'PnL (%)', type:'number',  valueFormatter: formatOurPerc, width:68},
    //{ field: 'sparkdata', headerName: 'Utveckling', type:'string', renderCell: getSpark },
    
  ],
  // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  
  function pxv(params) {
    if (params.value === null) {return ""}
    return (Math.round(params.row.volume*params.row.price).toLocaleString());
    
  }
  
  function addSEK(params) {
    if (params.value === null) {return ""}
    return (params.value+" SEK");
    
  }
  
  
  function updateLob(params) {
    loadLobData(modalData[0].isin,params.row.time);
  }
  
  const closedtradescolumn: GridColDef[] = [
    { field: 'actions', headerName: 'Orderbok', type:'actions', width:68, 
    getActions: (params) => [
      <GridActionsCellItem icon={<SortIcon />} onClick={() => updateLob(params)} label="lob"  />,
    ]
     },
    { field: 'id', headerName: 'id', type:'number' },
    { field: 'time', headerName: 'Tidsstämpel', type:'dateTime', width:150},
    { field: 'price', headerName: 'Pris', type:'number' },
    { field: 'volume', headerName: 'Antal', type:'number' },
    { field: 'pxv', headerName: 'Pris x Antal', type:'number', valueGetter: pxv, valueFormatter:addSEK },
   
   
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ];
  
  
  
  const tradelogcolumns: GridColDef[] = [
    { field: 'actions', headerName: 'Orderbok', type:'actions', width:68, 
    getActions: (params) => [
      <GridActionsCellItem icon={<SortIcon />} onClick={() => updateLob(params)} label="lob"  />,
    ]
     },
    { field: 'id', headerName: 'id', type:'number' },
    { field: 'time', headerName: 'Tidsstämpel', type:'dateTime', width:150},
    { field: 'type', headerName: 'Typ', type:'string',width:68 },
    { field: 'side', headerName: 'Sida', type:'string',width:68, 
    
    cellClassName: (params: GridCellParams<number>) => {
      if (params.value == null) {
        return '';
      }
    
      return clsx('cellbg', {
        BUY: params.value === 'BUY',
        SELL: params.value === 'SELL',
      });
    },
    
    },
    { field: 'price', headerName: 'Pris', type:'number',width:68},
    { field: 'volume', headerName: 'Antal', type:'number',width:68},
    { field: 'reason', headerName: 'kommentar', type:'string',width:300 },
  
  ];
  
  //#191919 eller #1A2027
  const Item =  React.useMemo(
  () => styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    //padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
   
  })),
    []
  );
  



function Bidlob(props) {
  return (<Box sx={{mb:1}}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
    
    <Box sx={{textAlign:"left" }}>
      <Typography variant="body2" color="text.secondary">{props.volume.toLocaleString()}</Typography>
    </Box>
    <Box sx={{textAlign:"right" }}>
      <Typography variant="body2" color="text.secondary">{props.price.toLocaleString()}</Typography>
    </Box>
    </Box>
    
    <Box sx={{ width: '100%', transform: "rotate(180deg)"}}>
      <LinearProgress variant="determinate" value={((props.volume/maxPrice)*100)} sx={{ height:"10px", backgroundColor: '#1F232A', borderRadius: 1 }} />
    </Box></Box>
  );
}

function Asklob(props) {
return (<Box sx={{mb:1}}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
    
    <Box sx={{textAlign:"left" }}>
      <Typography variant="body2" color="text.secondary">{props.price.toLocaleString()}</Typography>
    </Box>
    <Box sx={{textAlign:"right" }}>
      <Typography variant="body2" color="text.secondary">{props.volume.toLocaleString()}</Typography>
    </Box>
    </Box>
    
    <Box sx={{ width: '100%'}}>
      <LinearProgress variant="determinate" value={((props.volume/maxPrice)*100)} color="error"  sx={{height:"10px", backgroundColor: '#1F232A', borderRadius: 1 }} />
    </Box></Box>
  );
}

function CustomToolbar() {
return (
  <GridToolbarContainer >
    <GridToolbarFilterButton />
    <GridToolbarExport 
    excelOptions={{
      allColumns: true,
      fileName: 'augur_news_export'
    }}
    csvOptions={{
      fileName: 'augur_news_export',
      delimiter: ';',
      utf8WithBom: true,
      allColumns: true
    }} />
    <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
  </GridToolbarContainer>
);
}


  return (
    <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        y: {xs:'1vh', sm:'1vh'},
      }}
        >  
        
        
        
   <Dialog open={openModal} onClose={handleCloseModal} fullScreen scroll='paper' sx={{
     
     '& .cellbg.green': { backgroundColor: 'success.dark'},
     '& .cellbg.red': { backgroundColor: 'error.dark'},
     '& .cellbg.BUY': { backgroundColor: 'info.dark'},
     '& .cellbg.SELL': { backgroundColor: 'secondary.dark'},
     
   }}>
   <DialogTitle sx={{backgroundColor:"#0E0E0E"}}>{modalData[0].name} ({modalData[0].symbol}) - {modalData[0].time}</DialogTitle>
    <DialogContent spacing={{xs:0, sm:2}} sx ={{ 
      p: {xs:0, sm:2},backgroundColor:"#0E0E0E"
     }}>
    <Box>
    <Grid container spacing={{xs:0, sm:2}} sx ={{ 
      p: {xs:0, sm:2},
     }}>
     
     
    <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"0px",sm:"0px"} }}>
   
    <Item sx={{border:0,color:'text.primary', py:'12px', px:'0px', height:'320px'}}>
    <Typography variant='subtitle1' >Avslut första timmen</Typography>
    
    <ResponsiveContainer width="96%" height="95%" margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
      <ComposedChart  
      data={tradeData}
      margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
      
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
        </linearGradient>
      </defs>


        <Area connectNulls dataKey="price" stroke="#8884d8" strokeWidth={2} dot={false} fillOpacity={1} fill="url(#colorUv)" />
        
        <Scatter dataKey="buyprice" fill="#1b5e20" />
        <Scatter dataKey="sellprice" fill="#c62828" />
        
        <XAxis 
          dataKey="time" 
          name = 'Time'
          tickFormatter = {(time) => moment(time).format('HH:mm')}
          //type = 'number'
          domain={['auto', 'auto']} 
           />
        <YAxis type="number" domain={['dataMin', 'auto']} />
        <Tooltip contentStyle={{backgroundColor: '#1A2027'}} itemStyle={{ color: '#fff' }} />
      </ComposedChart>
      </ResponsiveContainer>
      </Item>
      </Grid>
      
      <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
      
      <Item sx={{border:0,color:'text.primary', py:'12px', height:'320px'}}>
      <Typography variant='subtitle1' >Alla transaktioner</Typography>
      
      <DataGridPremium 

      loading={closedTradesLoading}
      hideFooter={true}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false},
        },
        filter: {
          filterModel: {
            items: [{ columnField: 'price', operatorValue: '>', value: '0' }],
          },
        },
      }}
      components={{ 
        LoadingOverlay: LinearProgress,
      }}
      localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
      sx={{ 
        fontSize: 9,
        '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
        border:0,
        height:"280px"
      }} 
      density="compact" 
      rows={tradeData} 
      columns={closedtradescolumn} 
      disableSelectionOnClick={true}
       />
      </Item>
      </Grid>
      
      
      <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
      
      <Item sx={{border:0,color:'text.primary', py:'12px', height:'320px'}}>
      <Typography variant='subtitle1' >Våra transaktioner</Typography>
               <DataGridPremium 
                 loading={Tradelogloading}
                 hideFooter={true}
                 initialState={{
                   columns: {
                     columnVisibilityModel: {
                       id: false},
                   },
                 }}
                 components={{ 
                   LoadingOverlay: LinearProgress,
                 }} 
  
                 localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
                 sx={{ 
                   fontSize: 9,
                   '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
                   border:0,
                   height:"280px"
                 }} 
                 density="compact" 
                 rows={Tradelog} 
                 columns={tradelogcolumns} 
                 disableSelectionOnClick={true}
                  disableColumnSelector
                  disableDensitySelector
                  />
                  
      </Item></Grid>
      
      <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
      
      <Item sx={{border:0,color:'text.primary', py:'12px', height:'320px'}}>
      <Typography variant='subtitle1' >Orderbok</Typography><Typography variant='paragaph' >{lobData[0].time}</Typography>
        <Grid container spacing={2} sx={{p:4}}>
      
        <Grid sx={{p:1, width:"50%"}}>
        <Box sx={{ display: 'flex',  justifyContent: 'space-between'}}>
          <Box>Antal</Box><Box>Köp</Box>
        </Box>
        <Bidlob volume={lobData[0].b1_v} price={lobData[0].b1_p} />
        <Bidlob volume={lobData[0].b2_v} price={lobData[0].b2_p} />
        <Bidlob volume={lobData[0].b3_v} price={lobData[0].b3_p} />
        <Bidlob volume={lobData[0].b4_v} price={lobData[0].b4_p} />
        <Bidlob volume={lobData[0].b5_v} price={lobData[0].b5_p} />
        </Grid>
        
        <Grid sx={{p:1, width:"50%"}}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
          <Box>Sälj</Box><Box>Antal</Box>
        </Box>
        <Asklob volume={lobData[0].a1_v} price={lobData[0].a1_p} />
        <Asklob volume={lobData[0].a2_v} price={lobData[0].a2_p} />
        <Asklob volume={lobData[0].a3_v} price={lobData[0].a3_p} />
        <Asklob volume={lobData[0].a4_v} price={lobData[0].a4_p} />
        <Asklob volume={lobData[0].a5_v} price={lobData[0].a5_p} />
        </Grid>
        
        </Grid>
        </Item>
      </Grid>
      
      </Grid></Box>
    </DialogContent>
    <DialogActions sx={{backgroundColor:"#0E0E0E"}}>
      <Button onClick={handleCloseModal}>Stäng</Button>
      </DialogActions>
  </Dialog>
        
<Grid container spacing={{xs:0, sm:2}} sx ={{ 
  p: {xs:0, sm:2},
 }}>
 
 
<Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"} }}>
 <Item sx={{height: '85vh'}}>

  <DataGridPremium 
      loading={loading}
      rows={tableData} 
      columns={columns} 
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          nid:false,
          language:false,
        isin:false},
        
        },
        aggregation: {
          model: {
            prediction: 'avg',
            prediction_v2: 'avg',
            takeposition: 'sum',
            goodwords: 'sum',
            stopwords: 'sum',
            s1_returns: 'avg',
            s30_returns: 'avg',
            m2_returns: 'avg',
            m30_returns: 'avg',
            pnl: 'sum',
            pnl_perc: 'avg',
            timeinmarket: 'avg',
            latency: 'avg',
            relative_position:'avg'},
        }
      }}
      components={{ 
        Toolbar: CustomToolbar,
        LoadingOverlay: LinearProgress
      }}
      localeText={svSE.components.MuiDataGrid.defaultProps.localeText}

        
        sx={{ fontSize: 9,
          '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
          '& .customrow--1': {bgcolor: 'primary.dark'},
          '& .customrow--1:hover':{bgcolor: 'primary.dark'},
          border:0,
        }}
        
      density="compact" 

      getRowClassName={(params) => `customrow--${params.row.takeposition}`}
     
      experimentalFeatures={{ 
        aggregation: true
         }} 

      disableSelectionOnClick={true}
      
       />
       
   </Item></Grid></Grid>
   </Box>
  );
}



export default Newstable;
