/* eslint no-eval: 0 */

import './App.css';
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton  } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


function Traindata() {
	
	const userinfo = JSON.parse(localStorage.getItem("userinfo"));
	const username = userinfo['username'];
  
  const [traindata,setTraindata]=useState([]);
  const defaultCatstats = [{username:"Laddar..."}]
  const [catstats,setCatstats]=useState(defaultCatstats);
  const defaultOnePR = [{headline:"Laddar..."}]
  const [onePR,setOnePR]=useState(defaultOnePR);
  const [Traindataloading, setTraindataloading] = useState(true);
  
  let totcat = 0;
  let perccat = 0;
  
  var heads = {}
  try {
	const cred = JSON.parse(localStorage.getItem("credentials"));
	const apikey = cred['access_token'];
	heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
	heads = {}
  }
  
  
  	const fetchCatstats = async () => {
				
			  fetch("http://46.246.30.100/api/get/catstats", { headers:heads})
				.then((response) => response.json())
				.then((data) => {setCatstats(data); })
				
			}
  
	const fetchTraindata = async () => {
		  
		fetch("http://46.246.30.100/api/get/traindata/100", { headers:heads})
		  .then((response) => response.json())
		  .then((data) => {setTraindata(data); setTraindataloading(false); })
		  
		  }
		  
	const fetchAllTraindata = async () => {
			  
			fetch("http://46.246.30.100/api/get/traindata", { headers:heads})
			  .then((response) => response.json())
			  .then((data) => {setTraindata(data); setTraindataloading(false); })
			  
			  }
	
	const catPR = async () => {
		
		onePR[0].username=username;
		
		let bodydata = {
			  "table":"training_dataset",
			  "row":onePR[0]
			}	
		  fetch("http://46.246.30.100/api/update", { 
			method: "post",
			body: JSON.stringify(bodydata)
		  }).then((response) => {fetchOnePR(); fetchCatstats(); })
		}
	
	const fetchOnePR = async () => {
				  
				fetch("http://46.246.30.100/api/get/traindata/1", { headers:heads})
				  .then((response) => response.json())
				  .then((data) => {setOnePR(data); })
				  
	}
  
	useEffect(()=>{

	  fetchTraindata();
	  fetchAllTraindata();
	  fetchOnePR();
	  fetchCatstats();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
  
	const saveTraindatatoDB = (newRow,oldRow) =>{
	  let bodydata = {
		  "table":"training_dataset",
		  "type":newRow.type,
		  "row":newRow
		}	
	  fetch("http://46.246.30.100/api/update", { 
		method: "post",
		body: JSON.stringify(bodydata)
	  })
	  return {...newRow};
	};
  
  
  

  
  const traindatacolumns: GridColDef[] = [
	 { field: 'id', headerName: 'id', type:'number',editable:false},
	 { field: 'language', headerName: 'Språk', type:'string',editable:false},
	 { field: 'news_date', headerName: 'Datum', type:'dateTime',editable:false},
	 { field: 'news_time', headerName: 'Tid', type:'dateTime',editable:false},
	 { field: 'sentiment', headerName: 'Sentiment', type:'string',editable:true},
	 { field: 'company', headerName: 'Bolag', type:'string',editable:false},
	 { field: 'headline', headerName: 'Rubik', type:'string',editable:false, width:500},
	 { field: 'eod_return', headerName: 'EOD %', type:'number',editable:false},
	 { field: 'eod_volume', headerName: 'EOD Volume', type:'number',editable:false},
	 { field: 'eod_volume_fx', headerName: 'FX', type:'string',editable:false},
	];

	
	
	function CustomToolbar() {
	return (
	  <GridToolbarContainer >
		<GridToolbarFilterButton />
		<GridToolbarExport 
		excelOptions={{
		  allColumns: true,
		  fileName: 'augur_training_export'
		}}
		csvOptions={{
		  fileName: 'augur_training_export',
		  delimiter: ';',
		  utf8WithBom: true,
		  allColumns: true
		}} />
		<GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
	  </GridToolbarContainer>
	);
	}
	
	//#191919 eller #1A2027
	  const Item =  React.useMemo(
	  () => styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		//padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	   
	  })),
		[]
	  );
	
  
  return (
	 <Box sx={{
		 display: 'flex',
		 alignItems: 'center',
		 justifyContent: 'center',
		 y: {xs:'1vh', sm:'1vh'},
	   }}
		 >     
	  
	  <Grid container spacing={{xs:0, sm:2}} sx ={{ 
		p: {xs:0, sm:2},
	   }}>
	   
	   <Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"}}}> 
	   <Item sx={{height: '80vh', py:'2vh'}}>
	   {onePR[0].eod_return} % | {onePR[0].eod_volume} {onePR[0].eod_volume_fx} | {onePR[0].news_date} {onePR[0].news_time} | {onePR[0].company}
	   <TextField 
	   sx={{my:2}}
	   fullWidth
	   multiline
	   InputProps={{
		   inputProps: {
			   style: { minHeight:100, textAlign: "center",  },
		   }
	   }}
	   size={"medium"}
	   	value={onePR[0].headline} 
	   	onKeyDown= {(e) => {
			   if (e.key === '1') {onePR[0].sentiment=1; catPR();}
			   if (e.key === '2') {onePR[0].sentiment=2; catPR()}
			   if (e.key === '3') {onePR[0].sentiment=3; catPR()}
	   }}
	   />
<Box sx={{display: 'flex',
	  justifyContent: 'center', p:5}}>
	   <Button 
	   color="error"
	   variant="contained" 
	   size="large"
	   sx={{mx:2}}
	   onClick={() => {onePR[0].sentiment=1; catPR();}}
	   >Negativ</Button>
	   <Button 
		  color="primary"
		  variant="contained" 
		  size="large"
		  sx={{mx:2}}
		  onClick={() => {onePR[0].sentiment=2; catPR();}}
		  >Neutral</Button>
		<Button 
		  color="success"
		  variant="contained" 
		  size="large"
		  sx={{mx:2}}
		  onClick={() => {onePR[0].sentiment=3; catPR();}}
		  >Positiv</Button>
		  </Box>
		  <Box>
		  Klicka i textrutan och använd tangenterna<br />1=Negativ 2=Neutral 3=Positiv
		  </Box>
			
		  <TableContainer sx={{display: 'flex',
			justifyContent: 'center', my:5}}>
		  <Table size="small" sx={{width:500}}>
		  <TableHead>
		  	<TableCell align="center"></TableCell>
			<TableCell align="center">Negativ</TableCell>
			<TableCell align="center">Neutral</TableCell>
			<TableCell align="center">Positiv</TableCell>
			<TableCell align="center">Total</TableCell>
		  </TableHead>
		  <TableBody>
		  {
			  catstats.slice(1).map(function (row) { 
				  
				  totcat = totcat+row.total
				  perccat = (totcat+catstats[0].total-catstats[0].uncat)/(totcat+catstats[0].total)*100;

				  return(
				<TableRow  scope="row">
				<TableCell align="center">{row.username}</TableCell>
				<TableCell align="center">{row.negative}</TableCell>
				<TableCell align="center">{row.neutral}</TableCell>
				<TableCell align="center">{row.positive}</TableCell>
				<TableCell align="center">{row.total}</TableCell>
				</TableRow>)
		  })
		  }

		  </TableBody></Table>
		  
		  </TableContainer>
		  <Box sx={{justifyContent: 'center',display: 'flex'}}>
		  <Box sx={{width:500}}>
		  <LinearProgress variant="determinate" value={perccat}  sx={{height:"10px", backgroundColor: '#1F232A', borderRadius: 1 }} /> 
		  
		  {(totcat+catstats[0].total-catstats[0].uncat)} av {totcat+catstats[0].total} ({Math.round((totcat+catstats[0].total-catstats[0].uncat)/(totcat+catstats[0].total)*10000)/100} %)
		  </Box></Box>
	   </Item>
	   </Grid>
	   
	  
		  <Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"} }}> 
				 
				  <Item sx={{height: '90vh'}}>
				 <DataGridPremium 
				   loading={Traindataloading}
				   hideFooter={false}
				   initialState={{
					 columns: {
					   columnVisibilityModel: {
						 id: false},
					 },
				   }}
				   components={{ 
				  Toolbar: CustomToolbar,
					 LoadingOverlay: LinearProgress
				   }} 
				   
				   localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
				   sx={{ 
					 fontSize: 9,
					 '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
					 border:0,
					}} 
				   density="compact" 
				   rows={traindata} 
				   columns={traindatacolumns} 
				   disableSelectionOnClick={true}
				   rowBuffer={20}
				   processRowUpdate={saveTraindatatoDB}
					 experimentalFeatures={{ 
					 newEditingApi: true }} 
					/>
					</Item>
					</Grid>
		  
		  </Grid>
		
		</Box>
	   

  );
}



export default Traindata;
