/* eslint no-eval: 0 */

import * as React from "react";
import { useState,useEffect } from "react";
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import CodeIcon from '@mui/icons-material/Code';
import StorageIcon from '@mui/icons-material/Storage';
import ExtensionIcon from '@mui/icons-material/Extension';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import Locked from '@mui/icons-material/HttpsOutlined';

import { DataGridPremium, GridColDef, svSE, } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';

import { Chart,BarSeries,LineSeries,ArgumentAxis,ValueAxis,Tooltip} from '@devexpress/dx-react-chart-material-ui';
import { Animation,EventTracker, Stack } from '@devexpress/dx-react-chart';
import Ticker from 'react-ticker'

function Overview() {
  const c_apps = ["augur_algo","tickerplant","returns_calculator"];
  const db_apps = ["Redis","Postgres"];
  const other_apps = ["Stunnel","Apache"];
  const [checked, setChecked] = useState([]);
  const [dailyReturns,setDailyReturns]=useState([]);
  const [dailyNewsCount,setDailyNewsCount]=useState([]);
  const [dailyLatency,setDailyLatency]=useState([]);
  const [dailyTurnover,setDailyTurnover]=useState([]);
  const [locked,setLocked]=useState(true);
  
  const [Positions,setPositions]=useState([]);
  const [todaysTrades,setTodaystrades]=useState([]);
  
  const [todaysTradesloading, setTodaystradesloading] = useState(true);
  const [Positionsloading, setPositionsloading] = useState(true);
  
  useEffect(()=>{
    
    const c_apps = ["augur_algo","tickerplant","returns_calculator"];
    const db_apps = ["Redis","Postgres"];
    const other_apps = ["Stunnel","Apache"];
    const apps = c_apps.concat(db_apps,other_apps);
  
  var heads = {}
  try {
    const cred = JSON.parse(localStorage.getItem("credentials"));
    const apikey = cred['access_token'];
    heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {heads = {}}
  
  const fetchStatus = async () => {
    const newChecked = [];
  
    for (const app of apps) {
      const resp = await fetch("http://46.246.30.100/api/status", { method: 'POST', headers:heads, body:JSON.stringify({service:app, action:'status'})});
      const data = await resp.json();

      if (data['status']) { newChecked.push(app);  }
    }
    setChecked(newChecked);
  }
  
  const fetchTables = async () => {
    fetch("http://46.246.30.100/api/get/todaysclosedtrades", { headers:heads})
    .then((response) => response.json())
    .then((data) => {setTodaystrades(data.slice().reverse()); setTodaystradesloading(false)})
    
    fetch("http://46.246.30.100/api/get/openpositions", { headers:heads})
    .then((response) => response.json())
    .then((data) => {setPositions(data); setPositionsloading(false); })
  }
  
  const fetchDailyGraphs = async () => {    
    
    fetch("http://46.246.30.100/api/graph/dailyturnover", { headers:heads})
    .then((response) => response.json())
    .then((data) => {setDailyTurnover(data.slice().reverse());})
  }
  
  const fetchIntradayGraphs = async () => {
    
    fetch("http://46.246.30.100/api/graph/dailynewscount", { headers:heads})
    .then((response) => response.json())
    .then((data) => {setDailyNewsCount(data.slice().reverse());})
    
    fetch("http://46.246.30.100/api/graph/dailylatency", { headers:heads})
    .then((response) => response.json())
    .then((data) => {setDailyLatency(data.slice().reverse());})
    
    fetch("http://46.246.30.100/api/graph/dailyreturns", { headers:heads})
    .then((response) => response.json())
    .then((data) => {setDailyReturns(data.slice().reverse());})
  }
  
  const fetchTicker = async () => {
    fetch("http://46.246.30.100/api/get/ticker", { headers:heads})
   .then((response) => response.text())
   .then((data) => {setTicker(data);})
    }
  
  fetchTicker();
  fetchTables();
  fetchStatus();
  fetchIntradayGraphs();
  fetchDailyGraphs();
  
  
  const interval_ticker = setInterval(() => {fetchTicker();}, 10000);
  const interval_tables = setInterval(() => {fetchTables();}, 5000);
  const interval_intragraphs = setInterval(() => {fetchIntradayGraphs();}, 30000);
  const interval_status = setInterval(() => {fetchStatus();}, 10000);
  const interval_dailygraphs = setInterval(() => {fetchDailyGraphs();}, 3600000);
  
  
  
  return () => {
    clearInterval(interval_ticker);
    clearInterval(interval_tables);
    clearInterval(interval_status);
    clearInterval(interval_intragraphs);
    clearInterval(interval_dailygraphs);
  }
  


  }, []);
  

  
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    let action = ""
    var heads = {}
    try {
      const cred = JSON.parse(localStorage.getItem("credentials"));
      const apikey = cred['access_token'];
      heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
    }
    catch { heads = {}}
    
    if (currentIndex === -1) {action = "start"} else { action = "kill"}
    
    fetch("http://46.246.30.100/api/status", { method: 'POST', headers:heads, body:JSON.stringify({service:value, action:action})})
    .then((response) => response.json())
    .then((data) => { 
    if (data['status']) { newChecked.push(value); setChecked(newChecked);  }
    else { newChecked.splice(currentIndex, 1); setChecked(newChecked);  }
    })
  };
  
  //#191919 eller #1A2027
  const Item =  React.useMemo(
  () => styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    //padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
   
  })),
    []
  );
  
  const ReturnsTooltip = ({ text, targetItem, ...restProps }) => {
    const displayText = dailyReturns[targetItem.point].exit_time+" | "+text+" SEK";
    return (
      <Tooltip.Content
        {...restProps}
        text={displayText}
        targetItem={targetItem}
      />
    );
  };
  
  const LatencyTooltip = ({ text, targetItem, ...restProps }) => {
    const displayText = dailyLatency[targetItem.point].received+" | "+text+" μs";
    return (
      <Tooltip.Content
        {...restProps}
        text={displayText}
        targetItem={targetItem}
      />
    );
  };
  
  const TurnoverTooltip = ({ text, targetItem, ...restProps }) => {
    const displayText = dailyTurnover[targetItem.point].time+" | "+text+" SEKm";
    return (
      <Tooltip.Content
        {...restProps}
        text={displayText}
        targetItem={targetItem}
      />
    );
  };
  
  const NewscountTooltip = ({ text, targetItem, ...restProps }) => {
    const displayText = dailyNewsCount[targetItem.point].time+" | "+text+" st";
    return (
      <Tooltip.Content
        {...restProps}
        text={displayText}
        targetItem={targetItem}
      />
    );
  };
  
  function formatPerc(params){
    return Math.round(params.value*100)/100+" %"; 
  }
  
  function formatRound(params){
    if (params.value === null) {return ""}
    const formattedValue = Math.round(params.value);
  
    return formattedValue.toLocaleString('se')+" SEK"; 
    
  }
  
  function formatOurPerc(params){
    if (params.value === null) {return ""}
    const formattedValue = ((Math.round(params.value*10000)/10000)*100);
  
    return formattedValue.toLocaleString('se')+" %"; 
    
  }
  
  function addMin(params) {
    if (params.value === null) {return ""}
    return (params.value+" min");
    
  }
  
  function addUs(params) {
    if (params.value === null) {return ""}
    return (params.value+" μs");
    
  }
  
  const todaysTradescolumns: GridColDef[] = React.useMemo(
  () => [
    { field: 'name', headerName: 'Bolag', type:'string', width:125 },
    { field: 'timeinmarket', headerName: 'Tid (min)', type:'number',width:68, valueFormatter:addMin,},
    { field: 'latency', headerName: 'Latens (μs)', type:'number',width:68, valueFormatter:addUs,},
    { field: 'pnl', headerName: 'PnL (SEK)', type:'number',width:68,  valueFormatter:formatRound,},
    { field: 'pnl_percentage', headerName: 'PnL (%)', type:'number', width:68, valueFormatter:formatOurPerc,
  cellClassName: (params: GridCellParams<number>) => {
    if (params.value == null) {
      return '';
    }
  
    return clsx('cellbg', {
      green: params.value > 0,
      red: params.value < 0,
    });
  },
  
  
   },
    { field: 'id', headerName: 'id', type:'number' },
  ],
    []
  );
  
  
  const positionscolumns: GridColDef[] = React.useMemo(
  () => [
    { field: 'name', headerName: 'Bolag', type:'string', width:125 },
    { field: 'timediff_minutes', headerName: 'Tid (min)', type:'number',width:68, valueGetter:addMin },
    { field: 'buyvalue', headerName: 'Storlek (SEK)', type:'number',width:90, valueGetter:formatRound },
    { field: 'percSold', headerName: '% Sålt', type:'number',width:68, valueGetter:formatPerc },
    { field: 'returns_perc', headerName: 'PnL (%)', type:'number', width:68, valueFormatter: formatPerc,
  cellClassName: (params: GridCellParams<number>) => {
    if (params.value == null) {
      return '';
    }
  
    return clsx('cellbg', {
      green: params.value > 0,
      red: params.value < 0,
    });
  },
  
  
   },
   { field: 'last_close', headerName: 'Senast Avslut', type:'number',width:68 },
    { field: 'id', headerName: 'id', type:'number' },
  ],
    []
  );
  
  const [ticker, setTicker] = useState(" ");
  
  function createTicker() { return {__html: ticker}; };
  
  return (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .cellbg.green': { backgroundColor: 'success.dark'},
    '& .cellbg.red': { backgroundColor: 'error.dark'},
    '& .cellbg.buy': { backgroundColor: 'info.dark'},
    '& .cellbg.sell': { backgroundColor: 'secondary.dark'},
    
    y: {xs:'1vh', sm:'1vh'},
    
  }}
    >  
    
  <Grid container spacing={{xs:0, sm:2}} sx ={{ 
    p: {xs:0, sm:2},
   }}>
   
   <Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"} }}>
    <Item sx={{border:0,color:'text.primary', py:'1vh'}}>
   
   <Ticker offset="run-in" speed={5}>
     {() => <Typography style={{ whiteSpace: "nowrap"  }}><span dangerouslySetInnerHTML={createTicker()} /> &nbsp;</Typography>}
   </Ticker>
   
   </Item></Grid>
   
   <Grid item xs={12} sm={6} md={4} sx={{ mt:{xs:"12px",sm:"0px"} }}>
   <Item sx={{border:0,color:'text.primary', py:'2vh', height:400}}>
   
   <Typography variant='subtitle1' onClick={event =>  window.location.href='/trades'}>Öppna Positioner »</Typography>
  
   
   <DataGridPremium 
   loading={Positionsloading}
   hideFooter={true}
   initialState={{
     columns: {
       columnVisibilityModel: {
         id: false,
       last_close: false},
     },
   }}
   components={{ 
     LoadingOverlay: LinearProgress   }}
   localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
   sx={{ 
     fontSize: 9,
     '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
     border:0,
    }} 
   density="compact" 
   rows={Positions} 
   columns={positionscolumns} 
   disableSelectionOnClick={true}
    />
    </Item>
    </Grid>
    <Grid item xs={12} sm={6} md={4} sx={{ mt:{xs:"12px",sm:"0px"} }}>
    <Item sx={{border:0,color:'text.primary', py:'2vh', height:400}}>
   <Typography variant='subtitle1' onClick={event =>  window.location.href='/news'} >Dagens Avslutade Affärer »</Typography> 
   <DataGridPremium 
    loading={todaysTradesloading}
    hideFooter={true}
    initialState={{
      columns: {
        columnVisibilityModel: {
          id: false},
      },
    }}
    components={{ 
      LoadingOverlay: LinearProgress,
    }}
    localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
    sx={{ 
      fontSize: 9,
      '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
      border:0,
     }} 
    density="compact" 
    rows={todaysTrades} 
    columns={todaysTradescolumns} 
    disableSelectionOnClick={true}
     />
     
   
   </Item></Grid>
   
  
   <Grid item xs={12} sm={6} md={4} sx={{ mt:{xs:"12px",sm:"0px"} }}>
   <Item sx={{border:0,color:'text.primary', py:'2vh'}}>
   <Typography variant='subtitle1' >PnL per dag (SEK)</Typography>
   <Chart
     height={340}
     data={dailyReturns}
   >
     <ArgumentAxis showLabels={false} />
     <ValueAxis />
   
     <BarSeries
       valueField="returns"
       argumentField="exit_time"
     />
     <Animation />
     <EventTracker />
     <Tooltip contentComponent={ReturnsTooltip} />
   </Chart>
   
   </Item></Grid>
   
   
   <Grid item xs={12} sm={6} md={3} sx={{ mt:{xs:"12px",sm:"0px"} }}>
    <Item sx={{border:0,color:'text.primary',py:'2vh'}}>
    <Typography variant='subtitle1' ># Nyheter per dag</Typography>
    <Chart
      height={340}
      data={dailyNewsCount}
    >
      <ArgumentAxis showLabels={false} />
      <ValueAxis />
    
      <BarSeries
        name="Position Taken"
        valueField="takeposition"
        argumentField="time"
      />
      <BarSeries
        name="No Position Taken"
        valueField="noposition"
        argumentField="time"
      />
      <Stack stacks={[
        { series: ['No Position Taken', 'Position Taken'] },
      ]}
      />
      <Animation />
      <EventTracker />
      <Tooltip contentComponent={NewscountTooltip} />
      
    </Chart>
    
    </Item></Grid>
   
   
   
   
   
     
      <Grid item xs={12} sm={6} md={3} sx={{ mt:{xs:"12px",sm:"0px"} }}>
      <Item sx={{border:0,color:'text.primary', py:'2vh'}}>
      <Typography variant='subtitle1' >Latens (μs)</Typography>
      <Chart
        height={340}
        data={dailyLatency}
      >
        <ArgumentAxis showLabels={false} />
        <ValueAxis />
      
        <LineSeries
          valueField="latency"
          argumentField="received"
        />
        <Animation />
        <EventTracker />
        <Tooltip contentComponent={LatencyTooltip} />
      </Chart>
      
      </Item></Grid>
      
      
      
      
      
      <Grid item xs={12} sm={6} md={3} sx={{ mt:{xs:"12px",sm:"0px"} }}>
      <Item sx={{border:0,color:'text.primary', py:'2vh'}}>
      <Typography variant='subtitle1' >Total Omsättning (SEKm)</Typography>
      <Chart
        height={340}
        data={dailyTurnover}
      >
        <ArgumentAxis showLabels={false} />
        <ValueAxis />
      
        <LineSeries
          valueField="turnover"
          argumentField="time"
        />
        <Animation />
        <EventTracker />
        <Tooltip contentComponent={TurnoverTooltip} />
      </Chart>
      
      </Item></Grid>
   
   
   
  <Grid item xs={12} sm={6} md={3} sx={{ mt:{xs:"12px",sm:"0px"} }}>
  <Item sx={{border:0,color:'text.primary',py:'2vh'}}>
  <Typography variant='subtitle1' >Status för Appar</Typography>
  <List 
  dense={true}
  >
  
  {c_apps.map(name => (  
    <ListItem>
    <ListItemIcon><CodeIcon /></ListItemIcon>
    <ListItemText>{name}</ListItemText>
    <Locked onClick={() => {locked ? setLocked(false):setLocked(true)}} />
    <Switch onChange={handleToggle(name)} checked={checked.includes(name)} disabled={locked} />
    </ListItem>
  ))}  
  
  <Divider />
  
  {db_apps.map(name => (  
    <ListItem>
    <ListItemIcon><StorageIcon /></ListItemIcon>
    <ListItemText>{name}</ListItemText>
    <Switch onChange={handleToggle(name)} checked={checked.includes(name)} disabled={true} />
    </ListItem>
  ))}  
  
  <Divider />
  
  {other_apps.map(name => (  
    <ListItem>
    <ListItemIcon><ExtensionIcon /></ListItemIcon>
    <ListItemText>{name}</ListItemText>
    <Switch onChange={handleToggle(name)} checked={checked.includes(name)} disabled={true} />
    </ListItem>
  ))}  
  
  </List>
  </Item></Grid>
  

  
  </Grid>

   </Box>
  );
}

export default Overview;
