import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));

document.title = "Augur"

root.render(
   <App />
);

