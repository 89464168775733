import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from "react";
import { Typography } from '@mui/material';


import { Chart,BarSeries,LineSeries,ArgumentAxis,ValueAxis,Tooltip} from '@devexpress/dx-react-chart-material-ui';
import { Animation,EventTracker,ValueScale } from '@devexpress/dx-react-chart';

function Performance() {
	
	const [dailyReturns,setDailyReturns]=useState([]);
	

  
  var heads = {}
  try {
	const cred = JSON.parse(localStorage.getItem("credentials"));
	const apikey = cred['access_token'];
	heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
	heads = {}
  }

	const fetchReturns = async () => {
	 fetch("http://46.246.30.100/api/graph/performance", { headers:heads})
	 .then((response) => response.json())
	 .then((data) => {setDailyReturns(data);})
	}
	
	
useEffect(()=>{
  fetchReturns();
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
	
//#191919 eller #1A2027
  const Item =  React.useMemo(
  () => styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	//padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
   
  })),
	[]
  );
  
  const modifyDailyDomain = domain => [Math.floor(domain[0]/5000)*5000, Math.ceil(domain[1]/5000)*5000];
  const modifyAggDomain = domain => [Math.ceil(domain[1]/50000)*50000*-1, Math.ceil(domain[1]/50000)*50000];
  
const ReturnsTooltip = ({ text, targetItem, ...restProps }) => {
	const displayText = dailyReturns[targetItem.point].time+" | "+text+" SEK";
	return (
	  <Tooltip.Content
		{...restProps}
		text={displayText}
		targetItem={targetItem}
	  />
	);
  };

	
	return (
		
		<Box sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			y: {xs:'1vh', sm:'1vh'}
		  }}>    
		
		<Grid container spacing={{xs:0, sm:2}} sx ={{ 
		  p: {xs:0, sm:2},
		 }}>
		 
		<Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
		 <Item sx={{border:0,color:'text.primary', py:'2vh'}}>
			<Typography variant='subtitle1' >Avkastning</Typography> 
			<Chart
			  height={400}
			  data={dailyReturns}
			>
			  <ArgumentAxis showLabels={false} />
			  
			  
			<ValueScale name='daily' modifyDomain={modifyDailyDomain} />
			<ValueScale name='agg' modifyDomain={modifyAggDomain} />
			<ValueAxis scaleName="daily" tickSize="2000" />
			<ValueAxis scaleName="agg" position="right" tickSize="2000" />
			
			  <BarSeries
			  	scaleName="daily"
				valueField="daily_return"
				argumentField="time"
			  />
			  
			  <LineSeries
			  		scaleName="agg"
				  valueField="cumsum"
				  argumentField="time"
				/>
				
			  <Animation />
			  <EventTracker />
			  <Tooltip contentComponent={ReturnsTooltip} />
			</Chart>
			
			
		 </Item>
		</Grid>
		</Grid>
		</Box>
		
	  );
}
	
	
	
export default Performance;