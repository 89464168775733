import './App.css';
import * as React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from "react";

function Logs() {
	
	const [logs,setLogs]=useState([]);


  
  var heads = {}
  try {
	const cred = JSON.parse(localStorage.getItem("credentials"));
	const apikey = cred['access_token'];
	heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
	heads = {}
  }

	const fetchLogs = async () => {
	  fetch("http://46.246.30.100/api/get/logs", { headers:heads})
	  .then((response) => response.json())
	  .then((data) => {setLogs(data); })
	}
	
	
useEffect(()=>{
  fetchLogs();
 // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
	
//#191919 eller #1A2027
  const Item =  React.useMemo(
  () => styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	//padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
   
  })),
	[]
  );
  
  const [value, setValue] = React.useState('1');
  
	const handleChange = (event, newValue) => {
	  setValue(newValue);
	};

	
	return (
		
		<Box sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			y: {xs:'1vh', sm:'1vh'}
		  }}>    
		
		<Grid container spacing={{xs:0, sm:2}} sx ={{ 
		  p: {xs:0, sm:2},
		 }}>
		 
		 
		<Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"} }}>
		 <Item sx={{textAlign:"left", whiteSpace:"pre-wrap"}}>
			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				  <TabList onChange={handleChange} aria-label="lab API tabs example">
					<Tab label="augur_algo" value="1" />
					<Tab label="tickerplant" value="2" />
					<Tab label="returns_calculator" value="3" />
				  </TabList>
				</Box>
				<TabPanel value="1">{logs.augur_algo}</TabPanel>
				<TabPanel value="2">{logs.tickerplant}</TabPanel>
				<TabPanel value="3">{logs.returns_calculator}</TabPanel>
			  </TabContext>
		 </Item>
		</Grid>
		</Grid>
		</Box>
		
	  );
}
	
	
	
export default Logs;