/* eslint no-eval: 0 */

import './App.css';
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton  } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';
import CheckIcon from '@mui/icons-material/Check';



function Stocks() {
  
  const [Isins,setIsins]=useState([]);
  const [Isinsloading, setIsinsloading] = useState(true);

  
  var heads = {}
  try {
	const cred = JSON.parse(localStorage.getItem("credentials"));
	const apikey = cred['access_token'];
	heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
	heads = {}
  }
  
  
	const fetchIsins = async () => {
		  
		fetch("http://46.246.30.100/api/get/isins", { headers:heads})
		  .then((response) => response.json())
		  .then((data) => {setIsins(data); setIsinsloading(false); })
		  
		  }
  
	useEffect(()=>{

	  fetchIsins();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
  
	const saveIsinstoDB = (newRow,oldRow) =>{
	  let bodydata = {
		  "table":"isins",
		  "type":newRow.type,
		  "row":newRow
		}	
	  fetch("http://46.246.30.100/api/update", { 
		method: "post",
		body: JSON.stringify(bodydata)
	  })
	  return {...newRow};
	};
  
  
  
  function getSignalCalc(params){
	  if (params.aggregation) { return params.formattedValue;}
	  else {
		  // eslint-disable-next-line 
		if (params.value == "1") {
		  return <CheckIcon color="text.primary" />;
		}
		return "";
	  }
	}
	
	function getExchange(params){
		if (params.value === '4711') { return 'Large & Mid Cap Stockholm';}
		else if (params.value === '4911') { return 'Small Cap Stockholm';}
		else if (params.value === '4697') { return 'First North Stockholm';}
		else if (params.value === '4789') { return 'NGM';}
		else if (params.value === '4929') { return 'Spotlight';}
		else if (params.value === '4981') { return 'Spotlight';}
		else if (params.value === '4984') { return 'Nordic SME';}
		else if (params.value === '5001') { return 'Nordic SME';}
	}
	
	const MemoizedgetSignal = React.memo(getSignalCalc);
	
	function getSignal(params: GridRenderCellParams<number>) {
	  return <MemoizedgetSignal {...params} />;
	}


  
  const isinscolumns: GridColDef[] = [
	  { field: 'id', headerName: 'id', type:'number',editable:false},
	  { field: 'tracking', headerName: 'Loggas', type:'singleSelect', valueOptions: ["0","1"],editable:true, width:68, renderCell: getSignal, filterable: false},
	  { field: 'trading', headerName: 'Handlas', type:'singleSelect', valueOptions: ["0","1"],editable:true, width:68, renderCell: getSignal, filterable: false},
	  { field: 'prediction_threshold', headerName: 'Prediction Threshold', type:'number',editable:true},
	  { field: 'lobb_threshold', headerName: 'LOB Balance Threshold', type:'number',editable:true},
	  { field: 'name', headerName: 'Namn', type:'string', width:200, editable:false},
	  { field: 'symbol', headerName: 'Ticker', type:'string',editable:false},
	  { field: 'isin', headerName: 'ISIN', type:'string',editable:false},
	  { field: 'identifier', headerName: 'NN id', type:'string',editable:false},
	  { field: 'market_sub_id', headerName: 'Marknad', type:'string',editable:false, valueFormatter: getExchange,},
	  { field: 'price', headerName: 'Senast dagsavslut', type:'number',editable:false},
	  { field: 'turnover', headerName: 'Omsättning', type:'number',editable:false},
	  { field: 'number_of_owners', headerName: 'Ägare på NN', type:'number',editable:false},
	];

	
	
	function CustomToolbar() {
	return (
	  <GridToolbarContainer >
		<GridToolbarFilterButton />
		<GridToolbarExport 
		excelOptions={{
		  allColumns: true,
		  fileName: 'augur_companies_export'
		}}
		csvOptions={{
		  fileName: 'augur_companies_export',
		  delimiter: ';',
		  utf8WithBom: true,
		  allColumns: true
		}} />
		<GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
	  </GridToolbarContainer>
	);
	}
	
	//#191919 eller #1A2027
	  const Item =  React.useMemo(
	  () => styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		//padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	   
	  })),
		[]
	  );
	
  
  return (
	 <Box sx={{
		 display: 'flex',
		 alignItems: 'center',
		 justifyContent: 'center',
		 y: {xs:'1vh', sm:'1vh'},
	   }}
		 >     
	  
	  <Grid container spacing={{xs:0, sm:2}} sx ={{ 
		p: {xs:0, sm:2},
	   }}>
	  
		  <Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"} }}> 
				 
				  <Item sx={{height: '80vh'}}>
				 <DataGridPremium 
				   loading={Isinsloading}
				   hideFooter={false}
				   initialState={{
					 columns: {
					   columnVisibilityModel: {
						 id: false},
					 },
				   }}
				   components={{ 
				  Toolbar: CustomToolbar,
					 LoadingOverlay: LinearProgress
				   }} 
				   
				   localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
				   sx={{ 
					 fontSize: 9,
					 '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
					 border:0,
					}} 
				   density="compact" 
				   rows={Isins} 
				   columns={isinscolumns} 
				   disableSelectionOnClick={true}

				   processRowUpdate={saveIsinstoDB}
					 experimentalFeatures={{ 
					 newEditingApi: true }} 
					/>
					</Item>
					</Grid>
		  
		  </Grid>
		
		</Box>
	   

  );
}



export default Stocks;
