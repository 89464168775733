

import './index.css';
import Newstable from './Newstable';
import Trades from './Trades';
import Login from './Login';
import Logs from './Logs';
import Stocks from './Stocks';
import Topbar from './Topbar';
import Overview from './Overview';
import Settings from './Settings';
import Traindata from './Traindata';
import Performance from './Performance';
import AuthWrapper from './AuthWrapper';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
//import { useState,useEffect } from "react";


const darkTheme = createTheme({
  palette: {
	mode: 'dark',
  },
});

function App() {

  return (
	<ThemeProvider theme={darkTheme}>
	<CssBaseline />
	<div className="App">
	<link
		rel="stylesheet"
		href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
	  />
	 <BrowserRouter>
	   <Routes>	   
	   <Route path="/login" element={<Login />} />
	   <Route element={<AuthWrapper />}>
	   		<Route path="/" element={<div><Topbar /><Overview /></div>} />
			<Route path="/news" element={<div><Topbar /><Newstable /></div>} />
			<Route path="/trades" element={<div><Topbar /><Trades /></div>} />
			<Route path="/stocks" element={<div><Topbar /><Stocks /></div>} />
			<Route path="/logs" element={<div><Topbar /><Logs /></div>} />
			<Route path="/settings" element={<div><Topbar /><Settings /></div>} />
			<Route path="/traindata" element={<div><Topbar /><Traindata /></div>} />
			<Route path="/performance" element={<div><Topbar /><Performance /></div>} />
			
		</Route>
		 
	   </Routes>
	 </BrowserRouter>
	</div>
	</ThemeProvider>
  );
}

export default App;