/* eslint no-eval: 0 */

import './App.css';
import * as React from "react";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridActionsCellItem, GridToolbarContainer, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';


import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function Settings() {
  

  const [Settings,setSettings]=useState([]);
  const [Words,setWords]=useState([]);
  
  const [Settingsloading, setSettingsloading] = useState(true);
  const [Wordsloading, setWordsloading] = useState(true);
  
  const defaultValuesNewSetting = {name:"",value:"", description:""};
  const [formValuesNewSetting, setFormValuesNewSetting] = useState(defaultValuesNewSetting);
  
  const defaultValuesNewWord = {word:"",type:"stopword", language:"sv", ranking:10};
	const [formValuesNewWord, setFormValuesNewWord] = useState(defaultValuesNewWord);
  
  var heads = {}
  try {
	const cred = JSON.parse(localStorage.getItem("credentials"));
	const apikey = cred['access_token'];
	heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
	heads = {}
  }
  
  
  const fetchSettings = async () => {
	  fetch("http://46.246.30.100/api/get/settings", { headers:heads})
		.then((response) => response.json())
		.then((data) => {setSettings(data); setSettingsloading(false); })
  }
  
  const fetchWords = async () => {
	  
	  fetch("http://46.246.30.100/api/get/words", { headers:heads})
		.then((response) => response.json())
		.then((data) => {setWords(data); setWordsloading(false); })
	  
	  }
	  
	  

  
	useEffect(()=>{
	  fetchSettings();
	  fetchWords();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
  
  const handleChangeNewSetting = (e) => {
	const { name, value } = e.target;
	setFormValuesNewSetting({
	...formValuesNewSetting,
	[name]: value,
	});
  };
 
  
  const [openNewSetting, setOpenNewSetting] = React.useState(false);
  const handleClickOpenNewSetting = () => {setOpenNewSetting(true);};
  const handleCloseNewSetting = () => {setOpenNewSetting(false);};
  
  
  const handleSubmitNewSetting = (event) => {
		event.preventDefault();
		
		let bodydata = {
			"table":"settings",
			"row":formValuesNewSetting
		  }
		
		fetch("http://46.246.30.100/api/insert", { 
		  headers:heads,
		  method: "post",
		  body: JSON.stringify(bodydata)
		})
		.then((data) => {setOpenNewSetting(false); fetchSettings();});
	  };
	  
  
	const deleteSetting= (params) => {
		
		let bodydata = {
			"table":"settings",
			"id":params.row.id
		  }		
		fetch("http://46.246.30.100/api/delete", { 
		  headers:heads,
		  method: "post",
		  body: JSON.stringify(bodydata)
		}).then((data) => {fetchSettings();});
	  };
	  
	  
	
	  const handleChangeNewWord = (e) => {
		const { name, value } = e.target;
		setFormValuesNewWord({
		...formValuesNewWord,
		[name]: value,
		});
	  };
	 
	  
	  const [openNewWord, setOpenNewWord] = React.useState(false);
	  const handleClickOpenNewWord = () => {setOpenNewWord(true);};
	  const handleCloseNewWord = () => {setOpenNewWord(false);};
	  
	  
	  const handleSubmitNewWord = (event) => {
			event.preventDefault();
			
			let bodydata = {
				"table":"words",
				"row":formValuesNewWord
			  }
			
			fetch("http://46.246.30.100/api/insert", { 
			  headers:heads,
			  method: "post",
			  body: JSON.stringify(bodydata)
			})
			.then((data) => {setOpenNewWord(false); fetchWords(); });
		  };
	  
	  
	  
	const deleteWord= (params) => {
			
			let bodydata = {
				"table":"words",
				"id":params.row.id,
				"type":params.row.type
			  }		
			fetch("http://46.246.30.100/api/delete", { 
			  headers:heads,
			  method: "post",
			  body: JSON.stringify(bodydata)
			}).then((data) => {fetchWords(); });
		  };
	  
	const saveSettingtoDB = (newRow,oldRow) =>{
		  let bodydata = {
			"table":"settings",
			"row":newRow
		  }
		
		  fetch("http://46.246.30.100/api/update", { 
			method: "post",
			body: JSON.stringify(bodydata)
		  })
		  return {...newRow};
		};
		
	const saveWordtoDB = (newRow,oldRow) =>{
	  let bodydata = {
		  "table":"words",
		  "type":newRow.type,
		  "row":newRow
		}	
	  fetch("http://46.246.30.100/api/update", { 
		method: "post",
		body: JSON.stringify(bodydata)
	  })
	  return {...newRow};
	};
	

  
  function CustomToolbar() {
  return (
	<GridToolbarContainer >
	<Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpenNewSetting}>Ny Inställning</Button>
	</GridToolbarContainer>
  );
  }
  

  const settingscolumns: GridColDef[] = React.useMemo(
  () => [
	{ field: 'id', headerName: 'id', type:'number' },
	{ field: 'name', headerName: 'Namn', type:'string', width:150,editable:true},
	{ field: 'value', headerName: 'Värde', type:'string',width:75,editable:true},
	{ field: 'description', headerName: 'Beskrivning', type:'string',width:300,editable:true},
	{ field: 'actions', headerName: '', type:'actions', width:25, 
	getActions: (params) => [
	  <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteSetting(params)} label="Delete"  />,
	]
	 },
  ],
  // eslint-disable-next-line react-hooks/exhaustive-deps
	  []
	);

  
  const wordscolumns: GridColDef[] = React.useMemo(
  () => [
	{ field: 'id', headerName: 'id', type:'number',editable:false},
	{ field: 'word', headerName: 'Ord', type:'string',editable:true, width:200},
	{ field: 'type', headerName: 'Typ',type:'singleSelect', valueOptions: ['goodword','stopword'],editable:true},
	{ field: 'language', headerName: 'Språk', type:'singleSelect', valueOptions: ['sv','en'],editable:true, width:50},
	{ field: 'ranking', headerName: 'Rank', type:'number',editable:true, width:50},
	{ field: 'actions', headerName: '', type:'actions', width:25, 
	getActions: (params) => [
	  <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteWord(params)} label="Delete"  />,
	]
	 },
  ],
  // eslint-disable-next-line react-hooks/exhaustive-deps
	  []
	);
  
  
  
  function CustomToolbarWords() {
	  return (
		<GridToolbarContainer >
		
		  <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpenNewWord}>
			  Nytt Ord
			</Button>
			
			
				<GridToolbarFilterButton />
			  <GridToolbarExport 
				excelOptions={{
				  allColumns: true,
				  fileName: 'augur_words_export'
				}}
				csvOptions={{
				  fileName: 'augur_words_export',
				  delimiter: ';',
				  utf8WithBom: true,
				  allColumns: true
				}} />
			
		  <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
		</GridToolbarContainer>
	  );
	}
	
	
	//#191919 eller #1A2027
	  const Item =  React.useMemo(
	  () => styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		//padding: theme.spacing(1),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	   
	  })),
		[]
	  );
	
  
  return (
	 <Box sx={{
		 display: 'flex',
		 alignItems: 'center',
		 justifyContent: 'center',
		 y: {xs:'1vh', sm:'1vh'},
	   }}
		 >     

	
	<Dialog open={openNewSetting} onClose={handleCloseNewSetting} >
	<form onSubmit={handleSubmitNewSetting}>
	  <DialogTitle>Ny Inställning</DialogTitle>
	  <DialogContent >
	  
	  	<TextField
		margin="dense"
		id="name"
		label="Namn"
		type="text"
		fullWidth
		variant="standard"
		name="name"
		value={formValuesNewSetting.name}
		onChange={handleChangeNewSetting}
		/>
	  
	  	<TextField
		margin="dense"
		id="value"
		label="Värde"
		type="text"
		fullWidth
		variant="standard"
		name="value"
		value={formValuesNewSetting.value}
		onChange={handleChangeNewSetting}
		/>
	  	
		<TextField
		margin="dense"
		id="description"
		label="Beskrivning"
		type="text"
		fullWidth
		variant="standard"
		name="description"
		value={formValuesNewSetting.description}
		onChange={handleChangeNewSetting}
		/>

	  </DialogContent>
	  <DialogActions>
		<Button onClick={handleCloseNewSetting}>Avbryt</Button>
		<Button type="submit">Spara</Button>
	  </DialogActions>
	  </form>
	  </Dialog>
	  
	<Dialog open={openNewWord} onClose={handleCloseNewWord} >
	<form onSubmit={handleSubmitNewWord}>
	  <DialogTitle>Nytt Ord</DialogTitle>
	  <DialogContent >
	  
	  	<InputLabel id="select-label2">Välj Typ</InputLabel>
			<Select
			  labelId="select-label2"
			  id="type"
			  value={formValuesNewWord.type}
			  label="Välj Typ"
			  fullWidth
			  onChange={handleChangeNewWord}
			  name = "type"
			>
			
			  <MenuItem value={'stopword'}>Stopword</MenuItem>
			  <MenuItem value={'goodword'}>Goodword</MenuItem>
			 
		  
			</Select>
			
		<InputLabel id="select-label">Välj Språk</InputLabel>
		<Select
		  labelId="select-label"
		  id="language"
		  value={formValuesNewWord.language}
		  label="Språk"
		  fullWidth
		  onChange={handleChangeNewWord}
		  name = "language"
		>
		
		  <MenuItem value={'sv'}>Svenska</MenuItem>
		  <MenuItem value={'en'}>Engelska</MenuItem>
		 
		
		</Select>
	  
		<TextField
		margin="dense"
		id="word"
		label="Ord"
		type="text"
		fullWidth
		variant="standard"
		name="word"
		value={formValuesNewWord.name}
		onChange={handleChangeNewWord}
		/>
	  
		<TextField
		margin="dense"
		id="ranking"
		label="Ranking"
		type="number"
		fullWidth
		variant="standard"
		name="ranking"
		value={formValuesNewWord.value}
		onChange={handleChangeNewWord}
		inputProps={{ step: "1" }}
		/>
		  
	
	  </DialogContent>
	  <DialogActions>
		<Button onClick={handleCloseNewWord}>Avbryt</Button>
		<Button type="submit">Spara</Button>
	  </DialogActions>
	  </form>
	  </Dialog>
	  
	  
	  <Grid container spacing={{xs:0, sm:2}} sx ={{ 
		p: {xs:0, sm:2},
	   }}>
	  
	  <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
		<Item sx={{height: '80vh'}}>
	<DataGridPremium 
	  loading={Settingsloading}
	  hideFooter={false}
	  initialState={{
		columns: {
		  columnVisibilityModel: {
			id: false},
		},
	  }}
	  components={{ 
		LoadingOverlay: LinearProgress,
		Toolbar: CustomToolbar
	  }}
	  localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
	  sx={{ 
		fontSize: 9,
		'& .MuiDataGrid-row:hover':{bgcolor: "unset" },
		border:0,
	  }} 
	  density="compact" 
	  rows={Settings} 
	  columns={settingscolumns} 
	  disableSelectionOnClick={true}
	  processRowUpdate={saveSettingtoDB}
	  experimentalFeatures={{ 
	  newEditingApi: true }} 
	   />
	   </Item>
	   </Grid>
	   

	   <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}> 
	   
		<Item sx={{height: '80vh'}}>
	   <DataGridPremium 
		 loading={Wordsloading}
		 hideFooter={false}
		 initialState={{
		   columns: {
			 columnVisibilityModel: {
			   id: false},
		   },
		 }}
		 components={{ 
		Toolbar: CustomToolbarWords,
		   LoadingOverlay: LinearProgress
		 }} 
		 componentsProps={{
			 toolbar: {
			   showQuickFilter: true,
			   quickFilterProps: { debounceMs: 250 },
			 },
		   }} 
		 localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
		 sx={{ 
		   fontSize: 9,
		   '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
		   border:0,
		  }} 
		 density="compact" 
		 rows={Words} 
		 columns={wordscolumns} 
		 disableSelectionOnClick={true}
		 disableColumnSelector
		 disableDensitySelector
		 processRowUpdate={saveWordtoDB}
		   experimentalFeatures={{ 
		   newEditingApi: true }} 
		  />
		  </Item>
		  </Grid>
		  </Grid>
		
		</Box>
	   

  );
}



export default Settings;
