/* eslint no-eval: 0 */

import './App.css';
import * as React from "react";
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useState,useEffect } from "react";
import { DataGridPremium, GridColDef, svSE, GridToolbarContainer, GridActionsCellItem, GridToolbarExport,GridToolbarQuickFilter,GridToolbarFilterButton  } from "@mui/x-data-grid-premium";
import LinearProgress from '@mui/material/LinearProgress';

import Locked from '@mui/icons-material/HttpsOutlined';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import clsx from 'clsx';

function Trades() {
  

  const [Tradelog,setTradelog]=useState([]);
  const [Positions,setPositions]=useState([]);
  const [Orders,setOrders]=useState([]);
  const [locked,setLocked]=useState(true);
  
  const [Tradelogloading, setTradelogloading] = useState(true);
  const [Positionsloading, setPositionsloading] = useState(true);
  const [Ordersloading, setOrdersloading] = useState(true);
  
  const defaultValuesNew = {"action":"new", isin:"",price:"", volume:"",side:"BUY",nid:""};
  const [formValuesNew, setFormValuesNew] = useState(defaultValuesNew);
  
  const defaultValuesModify = {};
  const [formValuesModify, setFormValuesModify] = useState(defaultValuesModify);

  
  var heads = {}
  try {
    const cred = JSON.parse(localStorage.getItem("credentials"));
    const apikey = cred['access_token'];
    heads = {'Authorization':'Bearer '+apikey,  'Content-Type': 'application/json'}
  }
  catch {
    heads = {}
  }

    const fetchTradelog = async () => {
      fetch("http://46.246.30.100/api/get/tradelog", { headers:heads})
      .then((response) => response.json())
      .then((data) => {setTradelog(data); setTradelogloading(false); })
    }
    
    const fetchPositions = async () => {
      fetch("http://46.246.30.100/api/get/openpositions", { headers:heads})
      .then((response) => response.json())
      .then((data) => {setPositions(data); setPositionsloading(false); })
    }
    
    
    const fetchOrders = async () => {
      fetch("http://46.246.30.100/api/get/openorders", { headers:heads})
      .then((response) => response.json())
      .then((data) => {setOrders(data); setOrdersloading(false); })
    }
    
    useEffect(()=>{
      fetchTradelog();
      fetchPositions();
      fetchOrders();
      
      const interval_Tradelog = setInterval(() => {fetchTradelog();}, 30000);
      const interval_Positions = setInterval(() => {fetchPositions();}, 1000);
      const interval_Orders = setInterval(() => {fetchOrders();}, 1000);

        
        return () => {
          clearInterval(interval_Tradelog);
          clearInterval(interval_Positions);
          clearInterval(interval_Orders);
        }
        
      
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  
  
  const handleChangeNew = (e) => {
    const { name, value } = e.target;
    setFormValuesNew({
    ...formValuesNew,
    [name]: value,
    });
  };
  
  const handleChangeModify = (e) => {
    const { name, value } = e.target;
    setFormValuesModify({
    ...formValuesModify,
    [name]: value,
    });
  };
  
  const [openNew, setOpenNew] = React.useState(false);
  const handleClickOpenNew = () => {setOpenNew(true);};
  const handleCloseNew = () => {setOpenNew(false);};
  
  const [openModify, setOpenModify] = React.useState(false);
  const handleCloseModify = () => {setOpenModify(false);};
      
  const deleteOrder = (params) => {
    fetch("http://46.246.30.100/api/order", { 
      headers:heads,
      method: "post",
      body: JSON.stringify({"action":"delete","oid":params.row.oid,"isin":params.row.isin})
    })
    .then((data) => {fetchOrders(); fetchTradelog();})
  };
  
  const modifyOrder = (params) => {
    setFormValuesModify({"action":"modify","isin":params.row.isin,"nid":params.row.nid,"oid":params.row.oid,"price":params.row.price,"volume":params.row.volume});
    setOpenModify(true);
  };
  
  
  const handleClickSync= () => {
    fetch("http://46.246.30.100/api/sync", { 
      headers:heads,
    })
    .then((data) => {fetchOrders(); fetchTradelog();})
  };
  
  const handleSubmitNew = (event) => {
        event.preventDefault();
        fetch("http://46.246.30.100/api/order", { 
          headers:heads,
          method: "post",
          body: JSON.stringify(formValuesNew)
        })
        .then((data) => {setOpenNew(false); fetchOrders(); fetchTradelog();})
      };
      
  const handleSubmitModify = (event) => {
    console.log(JSON.stringify(formValuesModify));
    event.preventDefault();
    fetch("http://46.246.30.100/api/order", { 
      headers:heads,
      method: "post",
      body: JSON.stringify(formValuesModify)
    })
    .then((data) => {setOpenModify(false); fetchOrders(); fetchTradelog();});
  };
  
  
  const handleToggle = (isin, nid, active) => {
    let action="";
    if (active) {action = "stop"} else { action = "start"}
    
    fetch("http://46.246.30.100/api/sellprogram", { method: 'POST', headers:heads, body:JSON.stringify({isin:isin, nid:nid, action:action})})
    .then((response) => response.json())
    .then((data) => { fetchPositions(); })
  };
  
  function CustomToolbar() {
  return (
    <GridToolbarContainer >
    <Button color="primary" startIcon={<AddIcon />} onClick={handleClickOpenNew}>Ny order</Button>
    <Button color="primary" startIcon={<SyncIcon />} onClick={handleClickSync}>Sync</Button>
    </GridToolbarContainer>
  );
  }
  
  function formatPerc(params){
    return Math.round(params.value*100)/100+" %"; 
  }
  
  function getSwitch(params){
    
    let sellprogram = false;
    if (params.aggregation) { return params.formattedValue;}
    else if (params.value !== null && params.value !== undefined && params.value !== "") {
    sellprogram = false;
    }
    else { sellprogram = true;}

    return <span> 
    <GridActionsCellItem icon={<Locked />} onClick={() => {locked ? setLocked(false):setLocked(true)}} label="Lås"  />
   <Switch onChange={() => {handleToggle(params.row.isin,params.row.nid,sellprogram)}} checked={sellprogram} disabled={locked} /></span>;
    
    
  }

  const tradelogcolumns: GridColDef[] = [
    { field: 'id', headerName: 'id', type:'number' },
    { field: 'name', headerName: 'Bolag', type:'string', width:150},
    { field: 'time', headerName: 'Tidsstämpel', type:'dateTime', width:150},
    { field: 'type', headerName: 'Typ', type:'string',width:68 },
    { field: 'side', headerName: 'sida', type:'string',width:68, 
    
    cellClassName: (params: GridCellParams<number>) => {
      if (params.value == null) {
        return '';
      }
    
      return clsx('cellbg', {
        buy: params.value === 'BUY',
        sell: params.value === 'SELL',
      });
    },
    
    },
    { field: 'price', headerName: 'Pris', type:'number',width:68},
    { field: 'volume', headerName: 'Antal', type:'number',width:68},
    { field: 'reason', headerName: 'kommentar', type:'string',width:300 },
    { field: 'isin', headerName: 'isin', type:'string' },
    { field: 'nid', headerName: 'nid', type:'string', width: 150 },
    { field: 'oid', headerName: 'oid', type:'string' },

  ];

  
  const orderscolumns: GridColDef[] = [
    { field: 'actions', width:75, headerName: '', type:'actions', getActions: (params) => [
      <GridActionsCellItem icon={<EditIcon />} onClick={() => modifyOrder(params)} label="Edit"  />,
      <GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteOrder(params)} label="Delete"  />,
    ]
     },
    
    { field: 'name', headerName: 'Bolag', type:'string' },
    { field: 'side', headerName: 'sida', type:'string' },
    { field: 'price', headerName: 'Pris', type:'number' },
    { field: 'volume', headerName: 'Antal', type:'number' },
    { field: 'last_price', headerName: 'Senast avslut', type:'number' },
    { field: 'ask', headerName: 'ask', type:'number' },
    { field: 'bid', headerName: 'bid', type:'number' },
    { field: 'id', headerName: 'id', type:'number' },
    { field: 'timestamp', headerName: 'Tidsstämpel', type:'dateTime'},
    { field: 'rkey', headerName: 'Redis key', type:'string' },
    { field: 'isin', headerName: 'isin', type:'string' },
    { field: 'nid', headerName: 'nid', type:'string', minWidth: 150 },
    { field: 'oid', headerName: 'oid', type:'string' },
    { field: 'synced', headerName: 'Synkad', type:'string' },
    

  ];
  
  const positionscolumns: GridColDef[] = [
    { field: 'manual_override', headerName: 'Säljprogram aktivt', type:'string', renderCell:getSwitch },
    
    { field: 'name', headerName: 'Bolag', type:'string' },
    { field: 'returns_perc', headerName: 'Utveckling', type:'number', valueFormatter: formatPerc,
  cellClassName: (params: GridCellParams<number>) => {
    if (params.value == null) {
      return '';
    }
  
    return clsx('cellbg', {
      green: params.value > 0,
      red: params.value < 0,
    });
  },
  
  
   },
    { field: 'gav', headerName: 'GAV', type:'number' },
    { field: 'last_price', headerName: 'Senast avslut', type:'number' },
    { field: 'ask', headerName: 'ask', type:'number' },
    { field: 'bid', headerName: 'bid', type:'number' },
    { field: 'volume', headerName: 'Antal', type:'number' },
    { field: 'hwm', headerName: 'HWM', type:'number' },
    { field: 'id', headerName: 'id', type:'number' },
    { field: 'rkey', headerName: 'Redis key', type:'string' },
    { field: 'isin', headerName: 'isin', type:'string' },
    { field: 'nid', headerName: 'nid', type:'string', minWidth: 150 },
    { field: 'synced', headerName: 'Synkad', type:'string' },
  ];
  
  //#191919 eller #1A2027
  const Item =  React.useMemo(
  () => styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    //padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
   
  })),
    []
  );
  
  
  function CustomToolbarTradelog() {
  return (
    <GridToolbarContainer >
      <GridToolbarFilterButton />
      <GridToolbarExport 
      excelOptions={{
        allColumns: true,
        fileName: 'augur_tradelog_export'
      }}
      csvOptions={{
        fileName: 'augur_tradelog_export',
        delimiter: ';',
        utf8WithBom: true,
        allColumns: true
      }} />
      <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
    </GridToolbarContainer>
  );
  }

  return (
     
    <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            y: {xs:'1vh', sm:'1vh'},
            '& .cellbg.green': { backgroundColor: 'success.dark'},
            '& .cellbg.red': { backgroundColor: 'error.dark'},
            '& .cellbg.buy': { backgroundColor: 'info.dark'},
            '& .cellbg.sell': { backgroundColor: 'secondary.dark'},
          }}>        

    <Dialog open={openNew} onClose={handleCloseNew} >
    <form onSubmit={handleSubmitNew}>
      <DialogTitle>Ny Order</DialogTitle>
      <DialogContent >
      
        <TextField
        margin="dense"
        id="nid"
        label="NID/Orderref"
        type="text"
        fullWidth
        variant="standard"
        name="nid"
        value={formValuesNew.nid}
        onChange={handleChangeNew}
        />
      
        <TextField
        margin="dense"
        id="isin"
        label="ISIN"
        type="text"
        fullWidth
        variant="standard"
        name="isin"
        value={formValuesNew.isin}
        onChange={handleChangeNew}
        />
        
        <TextField
        margin="dense"
        id="price"
        label="Pris"
        type="number"
        step="any"
        fullWidth
        variant="standard"
        name="price"
        value={formValuesNew.price}
        onChange={handleChangeNew}
        inputProps={{ step: ".0001" }}
        />
        
        <TextField
        margin="dense"
        id="volume"
        label="Antal"
        type="number"
        fullWidth
        variant="standard"
        name="volume"
        value={formValuesNew.volume}
        onChange={handleChangeNew}
        />
        
        <br /><br />
        <InputLabel id="select-label">Köp eller Sälj</InputLabel>
        <Select
        labelId="select-label"
        id="side"
        value={formValuesNew.side}
        label="Köp eller Sälj"
        fullWidth
        onChange={handleChangeNew}
        name = "side"
        >
        
        <MenuItem value={'BUY'}>Köp</MenuItem>
        <MenuItem value={'SELL'}>Sälj</MenuItem>

    
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseNew}>Avbryt</Button>
        <Button type="submit">Lägg Order</Button>
      </DialogActions>
      </form>
      </Dialog>
      
      
      
      <Dialog open={openModify} onClose={handleCloseModify} >
      <form onSubmit={handleSubmitModify}>
        <DialogTitle>Modifiera Order {formValuesModify.oid}</DialogTitle>
        <DialogContent >
          
          <TextField
          margin="dense"
          id="price"
          label="Pris"
          type="number"
          step="any"
          fullWidth
          variant="standard"
          name="price"
          value={formValuesModify.price}
          onChange={handleChangeModify}
          inputProps={{ step: ".0001" }}
          />
          
          <TextField
          margin="dense"
          id="volume"
          label="Antal"
          type="number"
          fullWidth
          variant="standard"
          name="volume"
          value={formValuesModify.volume}
          onChange={handleChangeModify}
          />
          
      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModify}>Avbryt</Button>
          <Button type="submit">Modifiera Order</Button>
        </DialogActions>
        </form>
        </Dialog>
      
      
    <Grid container spacing={{xs:0, sm:2}} sx ={{ 
      p: {xs:0, sm:2},
     }}>
     
     
    <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
     <Item sx={{height: '40vh'}}>

        
    <DataGridPremium 
      loading={Ordersloading}
      hideFooter={true}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false},
        },
      }}
      components={{ 
        LoadingOverlay: LinearProgress,
        Toolbar: CustomToolbar
      }}
      localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
      sx={{ 
        fontSize: 9,
        '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
        border:0,
      }} 
      density="compact" 
      rows={Orders} 
      columns={orderscolumns} 
      disableSelectionOnClick={true}
       />
       
       </Item></Grid>
       
      <Grid item xs={12} sm={12} md={6} sx={{ mt:{xs:"12px",sm:"0px"} }}>
        <Item sx={{height: '40vh'}}>
       
           
       <DataGridPremium 
         loading={Positionsloading}
         hideFooter={true}
         initialState={{
           columns: {
             columnVisibilityModel: {
               id: false},
           },
         }}
         components={{ 
           LoadingOverlay: LinearProgress
         }}
         localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
         sx={{ 
           fontSize: 9,
           '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
           border:0,
          }} 
         density="compact" 
         rows={Positions} 
         columns={positionscolumns} 
         disableSelectionOnClick={true}
          />
          
          </Item></Grid>
                 
        <Grid item xs={12} sm={12} md={12} sx={{ mt:{xs:"12px",sm:"0px"} }}>
         <Item sx={{height: '80vh'}}> 
                     
                 <DataGridPremium 
                   loading={Tradelogloading}
                   hideFooter={true}
                   initialState={{
                     columns: {
                       columnVisibilityModel: {
                         id: false},
                     },
                   }}
                   components={{ 
                     LoadingOverlay: LinearProgress,
                     Toolbar: CustomToolbarTradelog,
                   }} 
                 
                   localeText={svSE.components.MuiDataGrid.defaultProps.localeText}
                   sx={{ 
                     fontSize: 9,
                     '& .MuiDataGrid-row:hover':{bgcolor: "unset" },
                     border:0,
                   }} 
                   density="compact" 
                   rows={Tradelog} 
                   columns={tradelogcolumns} 
                   disableSelectionOnClick={true}
                    />
                    
        </Item></Grid>
                    
                    </Grid>
</Box>
       

  );
}



export default Trades;
