import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';import CellTowerIcon from '@mui/icons-material/CellTower';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import StorageIcon from '@mui/icons-material/Storage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const ResponsiveAppBar = () => {
	
const [state, setState] = React.useState({
	right: false,
  });
 
let username = "NA"
 
 try{ 
 	const userinfo = JSON.parse(localStorage.getItem("userinfo"));
 	username = userinfo['username'];
}
catch {  }
 
const toggleDrawer = (anchor, open) => (event) => {
	if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
	  return;
	}

	setState({ ...state, [anchor]: open });
  };
  
  
  
 function logout() {
	  //localStorage.setItem('credentials', "");
	  localStorage.removeItem("credentials");
 }

  const list = (anchor) => (
	<Box
	  sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
	  role="presentation"
	  onClick={toggleDrawer(anchor, false)}
	  onKeyDown={toggleDrawer(anchor, false)}
	>
	
	<List>
	  <ListItem >
		<ListItemIcon>
			<AccountBoxIcon />
		  </ListItemIcon>
		  <ListItemText primary={username.charAt(0).toUpperCase() + username.slice(1)} />
	  </ListItem>
	  
	  </List>
					
	<Divider />
	
	  <List>
		  <ListItem disablePadding>
			<ListItemButton component='a' href="/" >
			  <ListItemIcon>
				<DashboardIcon />
			  </ListItemIcon>
			  <ListItemText primary='Översikt' />
			</ListItemButton>
		  </ListItem>
		  
		  <ListItem disablePadding>
			  <ListItemButton component='a' href="/news" >
				<ListItemIcon>
				  <CellTowerIcon />
				</ListItemIcon>
				<ListItemText primary='Nyheter' />
			  </ListItemButton>
			</ListItem>
			
		<ListItem disablePadding>
		  <ListItemButton component='a' href="/stocks" >
			<ListItemIcon>
			  <AccountBalanceIcon />
			</ListItemIcon>
			<ListItemText primary='Bolag' />
		  </ListItemButton>
		</ListItem>
			
		<ListItem disablePadding>
		  <ListItemButton component='a' href="/trades" >
			<ListItemIcon>
			  <SyncAltIcon />
			</ListItemIcon>
			<ListItemText primary='Ordrar och Positioner' />
		  </ListItemButton>
		</ListItem>
		
		<ListItem disablePadding>
		  <ListItemButton component='a' href="/performance" >
			<ListItemIcon>
			  <AutoGraphIcon />
			</ListItemIcon>
			<ListItemText primary='Avkastning' />
		  </ListItemButton>
		</ListItem>
		
		<ListItem disablePadding>
		  <ListItemButton component='a' href="/logs" >
			<ListItemIcon>
			  <TextSnippetOutlinedIcon />
			</ListItemIcon>
			<ListItemText primary='Loggar' />
		  </ListItemButton>
		</ListItem>
		
		<ListItem disablePadding>
		  <ListItemButton component='a' href="/settings" >
			<ListItemIcon>
			  <SettingsOutlinedIcon />
			</ListItemIcon>
			<ListItemText primary='Inställningar' />
		  </ListItemButton>
		</ListItem>
		
		</List>
			  
			  <Divider />
		<List>
		
		<ListItem disablePadding>
		  <ListItemButton component='a' href="/traindata" >
			<ListItemIcon>
			  <ModelTrainingIcon />
			</ListItemIcon>
			<ListItemText primary='Training Dataset' />
		  </ListItemButton>
		</ListItem>
		  
		  
		<ListItem disablePadding>
		  <ListItemButton component='a' href="http://algo.haag.se/pgadmin4/" >
			<ListItemIcon>
			  <StorageIcon />
			</ListItemIcon>
			<ListItemText primary='Databas' />
		  </ListItemButton>
		</ListItem>
		  
		  
	  </List>
	  
	  <Divider />
	  <List>
	  <ListItem disablePadding>
		<ListItemButton component='a' href="/" onClick={logout} >
		  <ListItemIcon>
			<LogoutIcon />
		  </ListItemIcon>
		  <ListItemText primary='Logga ut' />
		</ListItemButton>
	  </ListItem>
	  </List>
	</Box>
  );

  return (
		
	<Box sx={{ flexGrow: 1 }}>
	<AppBar position="static"  style={{ background : "#1C2026" }}>
		<Toolbar>
		<RocketLaunchOutlinedIcon sx={{color:'white', ml:1}} onClick={event =>  window.location.href='/'} />
		<Typography variant="h6" align="left" component="div" sx={{ml:2, flexGrow:1 }} onClick={event =>  window.location.href='/'}>
			Augur AI
		</Typography>
			
			<React.Fragment key='right'>
				<Button sx={{color:'white',mr:-2}} startIcon={<MenuIcon />} onClick={toggleDrawer('right', true)}></Button>
				<Drawer anchor='right' open={state['right']} onClose={toggleDrawer('right', false)} >
				  {list('right')}
				</Drawer>
			  </React.Fragment>
			

		</Toolbar>
	</AppBar>
	</Box>
  );
};
export default ResponsiveAppBar;
